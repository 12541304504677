import React from 'react';
import { Row, Col } from 'antd';
import { Layout as AntLayout, Menu } from 'antd';
import { ReactComponent as LogoHorizontal } from '../../assets/LogoHorizontal.svg';

import { ReactComponent as Home } from '../../assets/Home.svg';
import styled from 'styled-components';
import { useMediaQuery } from 'shared/utils';

const { Header: AntHeader } = AntLayout;

const HeaderAnt = styled(AntHeader)`
  background: #ffffff;
  min-height: 80px;
  padding: 0px 31px 0px 60px;

  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    bottom: -7px;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 3px solid #ff1744;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #3742fa;
  }

  .ant-menu-horizontal > .ant-menu-item-selected a {
    color: #3742fa !important ;
  }
`;

const Header = () => {
  const isDesktop = useMediaQuery('(min-width: 609px)');
  return (
    <HeaderAnt>
      <Row style={{ margin: 'auto', maxWidth: '1440px' }}>
        <Col
          xs={16}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          xxl={6}
          style={{
            display: "grid",
            alignContent: "center",
            height: '80px',
          }}>
          <LogoHorizontal style={{ width: "130px" }} />
        </Col>
        {isDesktop && (
          <>
            <Col xs={8} sm={12} md={12} lg={18} xl={18} xxl={18}>
              <Menu
                mode='horizontal'
                defaultSelectedKeys={['1']}
                style={{ borderBottom: '0px', justifyContent: 'end', fontWeight: '500', fontFamily: '"Roboto", sans-serif', color: '#1E3799', fontSize: '16px', paddingTop: '9px' }}>
                <Menu.Item key='1'>
                  <a href='#Home'>
                    <Home style={{ color: '#1E3799' }} />
                  </a>
                </Menu.Item>
                <Menu.Item key='2'>
                  <a href='#iCancha' style={{ color: '#1E3799' }}>
                    iCancha
                  </a>
                </Menu.Item>
                <Menu.Item key='3'>
                  <a href='#Centro-Deportivo' style={{ color: '#1E3799' }}>
                    Soy un Centro Deportivo
                  </a>
                </Menu.Item>
                {/*<Menu.Item key="4">
                            FAQ´s
                        </Menu.Item>*/}
                <Menu.Item key='5'>
                  <a href='https://sari.icancha.com/' target='_blank' rel='noreferrer' style={{ color: '#1E3799' }}>
                    Ingresa a SARI
                  </a>
                </Menu.Item>
              </Menu>
            </Col>
          </>
        )}

      </Row>
    </HeaderAnt>
  );
};

export default Header;
