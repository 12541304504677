import React from 'react';
import { Button, Row, Col } from "antd";
import { Layout as AntLayout } from 'antd';
import { ReactComponent as FooterLogoHorizontal } from "../../assets/LogoHorizontalMovil.svg";
import { WhatsAppOutlined } from "@ant-design/icons";
import { ReactComponent as Facebook } from "../../assets/ic-Facebook.svg";
import { ReactComponent as Instagram } from "../../assets/ic-Instagram.svg";
import { ReactComponent as Twitter } from "../../assets/ic-Twitter.svg";
import { ReactComponent as Colombia } from "../../assets/ic-Colombia.svg";
import { ReactComponent as Ecuador } from "../../assets/ic-Ecuador.svg";
import styled from "styled-components";
const { Footer: AntFooter } = AntLayout;

const FooterAnt = styled(AntFooter)`
    background: rgb(236, 240, 244);
    padding: 0px;

    .Row-left--content {
        padding: 80px 0px;
        justify-content: space-evenly;
    }

    .Col-leftfooter--logo {
        display: flex; 
        svg {
            width: 190px;
        }
    }

    .Col-leftfooter--info {
        color: #ffff;

        span {
            font-weight: 400;
            font-family: "Roboto", sans-serif;
            margin-bottom: 0px;
            color: #ffff;
            padding: 0px;
            font-size: 16px;
        }
    }

    .Col-leftfooter--infoContact {

        .buttonWs {
            background: #00D3B0 !important;
            border-radius: 10px;
            width: 100%;
            height: 36px;
            font-size: 16px;
            font-weight: 500;
            font-family: "Rubik", sans-serif;
            border-color: #00D3B0;
            line-height: 2;
            
            span {
                color: #fff;
            }
            
        }

       

        p {
            color: #ffff;
            margin-bottom: 0px;
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-weight: 400;
        }

        span {
            color: #00D3B0;
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-weight: 400;
        }
    }

    .Row-Content--social {
        padding: 80px 0 0 35px;

        p {
            color: #ffff;
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-weight: 400;
        }

    }

    .Row-Content--copyright {
        max-width: 1440px;
        margin: auto;
        padding-left: 60px;
        height: 55px;
        align-items: center;
        background: #1B1463;
    }

    .Col-Content--copyright {
        p {
            color: #ffff;
            margin-bottom: 0px;
            font-family: "Roboto", sans-serif;
            font-weight: 700;
        }

    } 

    .Col-Content--flags {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        p {
            color: #ffff;
            margin-bottom: 0px;
            font-family: "Roboto", sans-serif;
            font-weight: 700;
        }
    }

    .gradiente-border {
        max-width: 1440px;
        margin: auto;
        height: 2px;    
        display: flex;
        background-image: linear-gradient(to right, rgb(66, 27, 136) 0%, rgb(255, 23, 68) 100%);
    }


@media (max-width: 992px) {
    .Row-left--content {
        margin: 0 50px;
    }

   .Col-leftfooter--info {
       padding: 40px 0px;
       span {
           font-size: 18px;
       }
   }

   .Col-leftfooter--infoContact {
    
    .button-ws {
        height: 64px !important;
        margin-bottom: 10px;
        span {
            font-size: 19px;
        }
    }

    p { 
        font-size: 18px !important;
    }
    
   }

   .Row-Content--social {
        padding-bottom: 80px !important; 
        padding-left: 50px !important;
        
        svg { 
            height: 42px;
            width: 42px;
        }

        .icon-twitter {
            height: 40px;
            width: 40px;
        }

        p {
            font-size: 18px !important;
        }
    }

    .Row-Content--copyright {
        height: 120px !important; 
        margin-left: 0px !important;
        padding-left: 0px !important;
        p {
            font-size: 18px;
        }

        svg {
            width: 28px !important;
            height: 21px !important;
        }
        

        .Col-Content--copyright {
            text-align: center;
            order: 2 !important;
            margin-bottom: 20px;
        }

        .Col-Content--flags {
            justify-content: center !important;
            margin-top: 20px;
        }
    }
}

`;




const Footer = ({ country }) => {
    return (
        <FooterAnt >
            {/*<div style={{ maxWidth: "1440px", margin: "auto" }}>*/}
            {/*************************************** Col left firts row ********************************************/}
            <Row style={{ maxWidth: "1440px", margin: "auto" }}>
                <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18} style={{ background: "#1B1463" }}>
                    <Row className="Row-left--content">
                        <Col className="Col-leftfooter--logo" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                            <FooterLogoHorizontal />
                        </Col>
                        <Col className="Col-leftfooter--info" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                            <Button
                                type="link"
                                target="_blank"
                                href='https://drive.google.com/file/d/18yK_Jd_LpxlADAFpdbzyw0QTmIMPUhJK/view?usp=sharing'
                            >
                                Términos y Condiciones
                            </Button>
                            <br />
                            <Button
                                type="link"
                                target="_blank"
                                href=' https://drive.google.com/file/d/1g2skZV-cy-kiudl37YynfFgJaUrnWE2E/view?usp=sharing'
                            >
                                Habeas Data
                            </Button>
                            <br />
                            <Button
                                type="link"
                                target="_blank"
                                href='https://drive.google.com/file/d/1DBEwBHOX1z6f7eTxyW9ad9N7mJV-C_72/view?usp=sharing'
                            >
                                Políticas de Privacidad
                            </Button>
                            <br />
                            <Button
                                type="link"
                                target="_blank"
                                href='https://drive.google.com/file/d/1mcFSrD7YLg-9SchTjSkpB9PrpFrgsx_p/view?usp=sharing'
                            >
                                FAQ's
                            </Button>
                        </Col>
                        <Col className="Col-leftfooter--infoContact" xs={24} sm={24} md={24} lg={8} xl={6} xxl={6} >
                            <Row>
                                <Col style={{ display: "flex", width: "100%" }}>
                                    <Button
                                        className='buttonWs'
                                        target="_blank"
                                        href={country === "EC" ? `https://wa.me/593997220556?text=Hola Equipo iCancha!` : `https://wa.me/573157708440?text=Hola Equipo iCancha!`}
                                    >
                                        Escribenos en WhatsApp <WhatsAppOutlined />
                                    </Button>
                                </Col>
                                <Col span={24} style={{ borderBottom: "1px #ffff solid", marginTop: "15px" }}>
                                    <p>
                                        Llámanos a los teléfonos:
                                    </p>
                                </Col>
                                <Col span={24} style={{ marginTop: "15px" }}>
                                    <p>
                                        <span>+57 </span>315 770 8440
                                    </p>
                                    <p>
                                        <span>+593 </span>99 722 0556
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                {/*************************************** Col right firts row ********************************************/}
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{ background: "#1E3799" }}>
                    <Row className="Row-Content--social">
                        <Col span={24}>
                            <p>
                                Siguenos en:
                            </p>
                            <Button type="link" href='https://www.facebook.com/iCanchaCO/' target="_blank">
                                <Facebook />
                            </Button>
                            <Button type="link" href='https://www.instagram.com/icancha/' target="_blank">
                                <Instagram />
                            </Button>
                            <Button type="link" href='https://twitter.com/icancha_app' target="_blank">
                                <Twitter className='icon-twitter' />
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/***************************************  Seconds row ********************************************/}
            <div className='gradiente-border' />
            <Row className="Row-Content--copyright">
                <Col className="Col-Content--copyright" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                    <p>
                        &#169; iCancha {new Date().getFullYear()}
                    </p>
                </Col>

                <Col className="Col-Content--flags" Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} >
                    <Colombia />
                    <p style={{ margin: "0 10px" }}>
                        Colombia
                    </p>
                    <Ecuador />
                    <p style={{ margin: "0 30px 0 10px" }}>
                        Ecuador
                    </p>
                </Col>
            </Row>
        </FooterAnt>
    );
};

export default Footer;
