import styled, { css } from 'styled-components';
import { Row } from 'antd';

export const CardContent = styled(Row)`
  font-family: 'Roboto', sans-serif;
  justify-content: center;
  max-width: 1440px;
  margin: auto;

  .row--backtop {
    justify-content: end;
  }
  .button--floating-search {
    display: none;
  }

  .address-error {
    color: red;
    text-align: center;
    margin-top: -4px;
  }

  .ant-form-item {
    margin-bottom: unset;
  }

  ${(props) =>
    props.fixed &&
    css`
      .button--floating-search {
        box-shadow: 0 0 20px rgb(0 0 0 / 30%);
        max-width: 360px;
        padding: 5px;
        position: fixed;
        right: 5px;
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
        z-index: 1;
        display: block;
        bottom: 5px;
      }
    `}

  .Col-Content--InfoFields {
    background: #ffff;
    height: calc(100vh - 80px);
    z-index: 1;
    box-shadow: 9px 0px 10px -6.8px rgb(10 10 11 / 13%);
  }

  .Row-Select--menu {
    margin-left: 0px !important;
    margin-right: 0px !important;
    background: #f3f6f9;
    padding: 20px 40px;
    justify-content: center;

    .ant-select-selector {
      width: 127px;
      height: 45px;
      border-radius: 10px;
      border-color: #dfe8f1 !important;
    }

    .ant-col {
      height: 45px;
    }

    .ant-select-arrow {
      color: #1e3799;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: #dfe8f1;
    }

    .ant-input {
      width: 232px;
      height: 45px;
      border-radius: 10px;
      color: #1b1464;
      font-family: 'Roboto', san-serif;
      font-weight: 600;
      font-size: 14px;
      background: #ffffff;
      border-color: #dfe8f1;
      padding-top: 16px;
    }

    .ant-picker {
      width: 100%;
      height: 45px;
      border-radius: 10px;
      background: #ffffff;
      border-color: #dfe8f1;
    }
  }

  .Row-Content--sliderFields {
    height: Calc(100vh - 165px);
    justify-content: center;
    overflow-y: scroll;
  }

  .Col-Content--map {
    background: #a6acaf;
    height: calc(100vh - 80px);
    width: 100%;

    .styleMarker {
      width: 20px;
      height: 20px;
      background: #3742fa;
      border-radius: 50%;
      border: 2px solid #ffffff;
      box-shadow: 0px 4px 34px #3742fa;
    }
    .styleMarker-userLocation {
      width: 20px;
      height: 20px;
      background: #ff1744;
      border-radius: 50%;
      border: 2px solid #ffffff;
      box-shadow: 0px 4px 34px #ff1744;
    }
  }

  @media (max-width: 1427px) {
    .Row-Select--menu {
      padding: 20px 70px;
    }


    .Row-Content--sliderFields {
      height: calc(100vh - 227px);
    }
    
  }

  @media (max-width: 991px) {
    .Col-Content--map {
      display: none;
    }
    .Row-Content--sliderFields {
      height: calc(100vh - 173px);
    }
  }

  @media (max-width: 890px) {
    .Row-Select--menu {
      padding: 20px 100px;
    }
  }

  @media (max-width: 832px) {
    .Row-Content--sliderFields {
      height: calc(100vh - 227px);
    }
  }

  @media (max-width: 608px) {
    .Col-Content--InfoFields {
      background: linear-gradient(180deg, #1d3494 5.52%, #040033 152.71%);
      height: 232px;
    }
    .Row-Select--menu {
      padding: 20px !important;
      border-radius: 10px;
      max-width: 90%;
      margin: 17px auto !important;
      box-shadow: 0px 4px 12px 0px #616fa166 !important;

      .ant-col {
        width: 100%;
        .ant-select {
          width: 100% !important;
        }
        button {
          span {
            font-family: 'Rubik', sans-serif;
            font-size: 16px;
            font-weight: 400;
            color: #fff;
          }
        }
        .ant-select-selector {
          width: 100% !important;
        }

        .ant-input {
          width: 100% !important;
        }

        .ant-picker {
          width: 100% !important;
        }
      }

      .Col-data--picker {
        width: 50%;
      }
    }
    /* para reducir el height de la view y ajustarla */
    .Row-Content--sliderFieldsMin {
      height: calc(100vh - 199px) !important;
      justify-content: center !important;
    }
    .Row-Content--sliderFields {
      height: calc(100vh - 382px) !important;
    }
  }

  ${(props) =>
     !props.desktop && css`
     
      .Row-Content--sliderFields {
        height: auto !important;
        background-color: #e2e5ec;
        overflow-y: unset;
      }
    `
    }

  .dataNotFound--container {
    text-align: center;
    svg {
      stroke: #3742fa;
      fill: #3742fa;
      width: 80px;
      margin-top: 12px;
    }
    p {
      font-weight: 500;
      color: #ff1744;
    }
  }
`;

export const CardMap = styled(Row)`
  height: 104px;
  width: 306px;

  .Col-ImagField {
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-image {
      background: #f2f2f2;
      border-radius: 17px;
    }
  }

  .Col-infoCard {
    padding: 16px 25px 16px 0;
    display: grid;
    align-content: center;
    p {
      font-family: 'Roboto', sans-serif;
      margin-bottom: 0px;
    }
  }

  .Title-sede {
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .Inf-distance {
    margin: 0px;
    color: #000000;
    font-size: 12px;
  }

  .Inf-direction {
    font-weight: 400;
    font-size: 14px;
    color: #3742fa;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
