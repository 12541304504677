import axios from 'axios';
import { getToken } from './auth';
const utilitiesURL = process.env.NODE_ENV === 'production' ? 'https://api-rest.icancha.com/utilities' : 'http://localhost:8080/utilities';
const API_URL = process.env.NODE_ENV === 'production' ? 'https://api-rest.icancha.com/icapps' : 'http://localhost:8080/icapps';
const GEOIP_API_URL = `https://api.ipregistry.co/?key=${process.env.REACT_APP_IPREGISTRY_API_KEY}`;
const headers = { 'Content-Type': 'application/json' };

export const getGeolocationViaIP = async () => {
  try {
    const request = await axios.get(GEOIP_API_URL);
    const {
      data: { location },
    } = request;
    return {
      result: 200,
      geodata: {
        latitude: location.latitude,
        longitude: location.longitude,
        country: location.country.name,
        countryCode: location.country.code,
        city: location.city,
      },
    };
  } catch (error) {
    //console.log('Error getGeolocationViaIP', error);
  }
};

export const getCountries = async () => {
  try {
    const destination = `${utilitiesURL}/paises`;
    const connection = await axios({
      method: 'GET',
      url: destination,
      headers: headers,
    });
    const {
      data: { data, cod },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ getCountries: error });
  }
};

export const getCities = async (countryId) => {
  try {
    const destination = `${utilitiesURL}/ciudades?country=${countryId}`;
    const connection = await axios({
      method: 'GET',
      url: destination,
      headers: headers,
    });
    const {
      data: { data, cod },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ getCities: error });
  }
};

export const getSports = async () => {
  try {
    const destination = `${utilitiesURL}/deportes`;
    const connection = await axios({
      method: 'GET',
      url: destination,
      headers: headers,
    });
    const {
      data: { data, cod },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ getSports: error });
  }
};

export const getFields = async (date, time, sport, latitude, longitude, country) => {
  try {
    const destination = `${API_URL}/canchas?date=${date}&time=${time}&sport=${sport}&latitude=${latitude}&longitude=${longitude}&country=${country}`;
    const connection = await axios({
      method: 'GET',
      url: destination,
      headers: headers,
    });
    const {
      data: { data, cod },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ getFields: error });
  }
};

export const getField = async (idcancha) => {
  try {
    const destination = `${API_URL}/canchas/${idcancha}`;
    const connection = await axios({
      method: 'GET',
      url: destination,
      headers: headers,
    });
    const {
      data: { data, cod },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ getField: error });
  }
};

export const getFieldTarifas = async (idcancha, fecha, country) => {
  try {
    const destination = `${API_URL}/canchas/${idcancha}/tarifas?fecha=${fecha}&country=${country}`;
    const connection = await axios({
      method: 'GET',
      url: destination,
      headers: headers,
    });
    const {
      data: { data, cod },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ getFieldTarifas: error });
  }
};

export const getSedes = async (sport, latitude, longitude, name) => {
  try {
    let destination;
    if (name) {
      destination = `${API_URL}/sedes?sport=${sport}&latitude=${latitude}&longitude=${longitude}&name=${name}`;
    } else {
      destination = `${API_URL}/sedes?sport=${sport}&latitude=${latitude}&longitude=${longitude}`;
    }
    const connection = await axios({
      method: 'GET',
      url: destination,
      headers: headers,
    });
    const {
      data: { data, cod },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ getSedes: error });
  }
};

export const getSedesOnCentro = async (idcentro, latitude, longitude) => {
  try {
    let destination = `${API_URL}/centros/${idcentro}/sedes?latitude=${latitude}&longitude=${longitude}`;
    const connection = await axios({
      method: 'GET',
      url: destination,
      headers: headers,
    });
    const {
      data: { data, cod },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ getSedesInCentro: error });
  }
};

export const getFieldsOnSede = async (idsede) => {
  try {
    let destination = `${API_URL}/sedes/${idsede}/fields`;
    const connection = await axios({
      method: 'GET',
      url: destination,
      headers: headers,
    });
    const {
      data: { data, cod },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ getFieldsOnSede: error });
  }
};

export const getDocuments = async (country) => {
  try {
    let destination = `${utilitiesURL}/documentos?country=${country}`;
    const connection = await axios({
      method: 'GET',
      url: destination,
      headers: headers,
    });
    const {
      data: { data, cod },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ getDocuments: error });
  }
};

export const createBooking = async (timestamprsvp, timestamprsvpend, tarifa, idsede, idcentro, idcancha, cupon) => {
  try {
    ////console.log({ timestamprsvp, timestamprsvpend, tarifa, idsede, idcentro, idcancha });
    const customOrigin = process.env.REACT_APP_CENTER_KEY;
    let destination;

    if (customOrigin) {
      destination = `${API_URL}/centros/${idcentro}/canchas/${idcancha}/rsvp?origen=${customOrigin}`;
      //destination = `${API_URL}/centros/16/canchas/2/rsvp?origen=${customOrigin}`;
    } else {
      destination = `${API_URL}/centros/${idcentro}/canchas/${idcancha}/rsvp`;
      //destination = `${API_URL}/centros/16/canchas/2/rsvp`;
    }

    const bodyData = {
      timestamprsvp,
      timestamprsvpend,
      tarifa: parseFloat(tarifa),
      idsede,
      cupon: cupon ? cupon : '',
    };
    //console.log({ bodyData });
    const token = getToken();
    const connection = await axios({
      method: 'POST',
      url: destination,
      headers: { ...headers, Authorization: `Bearer ${token}` },
      data: bodyData,
    });

    const {
      data: { cod, data },
    } = connection;

    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ createBooking: error });
  }
};

///// metodo de infoCoupon /////
export const getInfoCoupon = async (coupon) => {
  try {
    let destination = `${utilitiesURL}/coupons/${coupon}`;
    const connection = await axios({
      method: 'GET',
      url: destination,
      headers: headers,
    });
    const {
      data: { data, cod },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    console.log({ getInfoCoupon: error });
  }
};
/////////////////////////////////

// Respuesta book positiva
/*
{
  "cod": 1000,
  "respuesta": "Solicitud procesada de forma exitosa",
  "data": {
      "timestamprsvp": "2022-02-25T23:00:00.000Z",
      "idcancha": "2",
      "tarifahorarsvp": 50000,
      "pagadoencentro": "0.00",
      "porpagarencentro": 50000,
      "utilidadicancha": 10000,
      "utilidadcentro": 40000,
      "timestamprsvpend": "2022-02-26T00:00:00.000Z",
      "creadopor": 1,
      "idorigenrsvp": 3,
      "idporcentajeic": 3,
      "idusuario": 8271,
      "fechacancelado": null,
      "canceladopor": null,
      "modificadopor": null,
      "idhistoricorsvp": 37349,
      "montopagoapp": "0.00",
      "montototaldscto": "0.00",
      "montodsctocentro": "0.00",
      "fechacreado": "2022-02-25T19:51:27.355Z",
      "jugado": false,
      "torneo": false,
      "cancelado": false,
      "facturar": false,
      "urlPago": "https://pagos.icancha.com?idprsvp=123734931"
  }
}
*/

// reservada
/* {
  "cod": 1000,
  "respuesta": "Solicitud procesada de forma exitosa",
  "data": {
      "reservada": true
  }
} */
