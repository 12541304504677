import styled from 'styled-components';
import { Layout as AntLayout } from 'antd';
import Viewmovil2 from '../../assets/ic-ViewMovil2.png';
import figure from '../../assets/figure1.png';

const { Content: AntContent } = AntLayout;

export const Layout = styled(AntLayout)`
#page-wrap {
  height: 100%;
  overflow: auto;
  text-align: center;
  overflow: auto;
}

.bm-menu-wrap {
  right: 0px !important;
}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: absolute;
  width: 30px;
  height: 22px;
  right: 36px;
  top: 27px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #1E3799;
  border-radius: 10px;
  height: 10% !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  width: inherit;
  background: #1b1464;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgb(27, 20, 100) !important;
  opacity: 0.3 !important;
}
`;

export const Content = styled(AntContent)`
  .Row-Contentbg {
    background-image: linear-gradient(#1e3799, #040033);
    min-height: 700px;
    display: flex;
    justify-content: center;
  }

  .Col-contentImg {
    z-index: 0;
    img {
      min-width: 50%;
      height: 700px;
      width: 1440px;
    }
  }

  .Col-menu--content {
    background: #1533a7;
    border-radius: 10px;
    min-width: 50%;
    min-height: 130px;
    top: 711px;
    display: flex;
    justify-content: center;
    padding: 20px;
    position: absolute;
    margin: 0 20px;
    align-items: center;
    
  }

  .Row-Select--menu {
    align-items: center;
    justify-content: center;

    .ant-select-selector {
      background: transparent;
      height: 52px;
      border-radius: 10px;
      padding-top: 8px;

      color: #ffffff;
      border-color: #476bf4;
      font-family: 'Rubik', sans-serif;
      font-size: 16px;
      font-weight: 400;
    }

    .Col-content--select {
      height: 52px;
    }

    .ant-picker {
      background: transparent;
      border-color: #616eff;
      height: 52px;
      border-radius: 10px;
      min-width: 50%;
      width: 147px;
    }

    .ant-picker-input > input {
      font-size: 16px;
      font-family: 'Rubik', sans-serif;
      color: #ffffff;
    }

    .ant-picker-suffix {
      color: #476bf4;
    }
  }

  .Col-Select--Title {
    top: -312px;
    left: 8px;
    display: flex;
    position: absolute;

    h1 {
      line-height: 50px;
      font-weight: 600;
      color: #fff;
      font-family: "Rubik", sans-serif;
      font-size: 52px;
      z-Index: 2; 
    }
  }

  .selectMenu {
    width: 147px;
    min-width: 50%;
    border-radius: 10px;
  }

  .buttonSearch {
    background: #3742fa;
    width: 127px;
    border-radius: 10px;
    border-color: #3742fa;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    height: 52px;
  }

  .textTitle::after {
    content: url(${figure});
    display: block;
    height: 20px;
    width: 100px;
    position: absolute;
    top: 81px;
    margin-left: 150px;
  }

  .buttonws {
    box-shadow: 0 36px 30px -24px rgb(0 211 176 / 55%);
    background: #00D3B0;
    color: #fff;
    border-radius: 10px;
    width: 300px;
    height: 83px;
    font-size: 32px;
    font-family: "Rubik", sans-serif;
    border-color: #00D3B0;
    font-weight: 600;
    z-index: 10;
    display: grid;
    justify-content: center;
    align-content: center;
  }

  .Row-titleIcons {
    margin-top: 130px;
  }

  .Col-titleIcons {
    display: grid;
    justify-content: center;
    p {
      font-family: 'Rubik', sans-serif;
      font-weight: 600;
      font-size: 36px;
      text-align: center;
      margin-bottom: 0px;
    }
  }

  .Row-infoic {
    background: #ffff;
    min-height: 700px;
  }

  .Col-infoic--img {
    display: flex;
  }

  .Col-infoic--icancha {
    padding-top: 140px;

    h1 {
      font-weight: 600;
      font-size: 36px;
      color: #1e3799;
      font-family: 'Rubik', sans-serif;
    }

    h2 {
      font-weight: 400;
      font-size: 26px;
      color: #1e3799;
      font-family: 'Rubik', sans-serif;
      line-height: 26px;
      margin-bottom: 20px;
    }

    p {
      font-weight: 700;
      font-size: 18px;
      color: #a1a1a1;
      font-family: 'Roboto', sans-serif;
      line-height: 24px;
    }

    strong {
      color: #1e3799;
    }
  }

  .Row-info {
    background: #1533a7;
    justify-content: center;
  }

  .Col-info--content {
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 0 60px;
  }

  .Row--info--content-text {
    justify-content: center;

    h1 {
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      font-family: 'Rubik', sans-serif;
      line-height: 40px;
      text-align: center;
    }

    p {
      color: #fff;
      font-size: 26px;
      font-weight: 400;
      font-family: 'Rubik', sans-serif;
      margin: 0px;
      text-align: center;
    }
  }




  .Row-infoapp {
    background: #ffff;
    height: 691px;
  }

  .Row-infoapp--contenttext {
    justify-content: center;
    margin-left: 30px;
    margin-top: 400px;
  }

  .Col-infoapp--text {
    h1 {
      font-family: 'Rubik', sans-serif;
      font-size: 36px;
      font-weight: 600;
      line-height: 43px;
      text-align: left;
      color: #1e3799;
      margin-bottom: 0px;
    }

    p {
      font-family: 'Rubik', sans-serif;
      font-size: 28px;
      font-weight: 600;
      line-height: 33.18px;
      text-align: left;
      color: #3742fa;
    }
  }

  .Col-infoapp-image {
    display: flex;
    justify-content: flex-end;
  }


  #page-wrap {
    height: 100%;
    overflow: auto;
    text-align: center;
    overflow: auto;
    //top: 45%;
    //position: relative;
  }

  .bm-menu-wrap {
    right: 0px !important;
  }

  .bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
  }

  .bm-item:hover {
    color: white;
  }

  .bm-burger-button {
    position: absolute;
    width: 30px;
    height: 22px;
    right: 36px;
    top: 27px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #1E3799;
    border-radius: 10px;
    height: 10% !important;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /* General sidebar styles */
  .bm-menu {
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    width: inherit;
    background: #1b1464;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgb(27, 20, 100) !important;
    opacity: 0.3 !important;
  }


  @media (max-width: 992px) {
    .Col-menu--content {
      top: 690px;
    }


    .Col-infoic--icancha {
      padding-top: 0px;
      padding-bottom: 90px;
    }

    .Row-infoapp {
      height: auto;
      flex-direction: column-reverse;
    }

    .Row-infoapp--contenttext {
      margin-top: 0px;
      margin-bottom: 100px;
    }

    .Col-infoapp-image {
      background: url(${Viewmovil2}) no-repeat;
      background-size: auto;
      background-position-x: right;
      height: 600px;
      margin-top: 130px;

      img {
        visibility: collapse;
      }
    }

    @media (max-width: 608px) {

      .Col-contentImg {
        img {
          display: none;
        }
      }


      .Col-menu--content {
        max-width: 374px;
        top: 530px;
        padding: 40px 20px;
      }

      .Row-Select--menu {
        .ant-col:first-child {
          width: 100% !important;
        }
        .Col-firstSelect {
          width: 100% !important;
        }

        .Col-first--SelectButtom {
          width: 100% !important;
        }

        .ant-col {
          width: 50%;
        }

        .firstSelect {
          width: 100% !important;
        }

        .selectMenu {
          width: 100% !important;
        }

        .buttonSearch {
          width: 100%;
        }

        .ant-picker {
          width: 100% !important;
        }
      }

      .Col-Select--Title {
        top: -278px;
        h1 {
          font-size: 40px;
          line-height: 40px;
        }
      }
    }

    @media (max-width: 576px) {
      .Col-titleIcons {
        p {
          font-size: 26px;
        }
      }
      .textTitle::after {
        top: 58px;
        margin-left: 82px;
      }

      .Row--info--content-text {
        h1 {
          font-size: 32px;
          margin-bottom: 30px;
        }
        p {
          font-size: 22px;
        }
      }

      .Col-infoapp--text {
        h1 {
          font-size: 32px;
        }

        p {
          font-size: 24px;
        }
      }
    }
  }
`;

