import React, { Suspense, useEffect, useState } from 'react';
import { Row, Col, Menu } from 'antd';
import { Layout as AntLayout } from 'antd';
import { ReactComponent as LogoHorizontal } from '../assets/LogoHorizontal.svg';
import styled, { css } from 'styled-components';
import { BrowserRouter as Router, Route, Link, Switch, useRouteMatch, Redirect, useLocation } from 'react-router-dom';

import LoaderComponent from 'components/UI/loader';
import { useMediaQuery } from '../shared/utils';
import SideBar2 from '../components/Layout/Sidebar2';

import { InnerRoutes } from 'routes';
import ModalS from 'components/UI/ModalS';

const { Header: AntHeader } = AntLayout;
const { Content: AntContent } = AntLayout;

const Content = styled(AntContent)`
  min-height: calc(100vh - 80px);
  background: #e2e5ec;

  @media (max-width: 608px) {
    height: 100vh;
  }
`;

const Layout = styled(AntLayout)`
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 3px solid #ff1744;
  }

  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    bottom: -9px;
  }

  .Col-left-logo {
    a {
      display: flex;
      align-items: center;
      height: 80px;
    }

    svg {
      width: 130px;
    }
  }

  .Col-right--menu {
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: flex-end;
  }

  .Button-login {
    width: 171px;
    height: 38px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 16px;
    border-radius: 10px;
    margin: 20px 15px 20px 0px;
  }

  .button-link-menu {
    font-weight: 500;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: #1e3799;
  }

  .button-primary-menu {
    margin-left: 10px;
    width: 112px;
    height: 38px;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: #ffff;
  }

  .Row-Select--menu {
    box-shadow: 0px 9px 10px -6.8px rgba(10, 10, 11, 0.13) inset;

    label {
      position: absolute;
      z-index: 10;
      font-size: 12px;
      top: 6px;
      left: 18px;
      font-family: 'Roboto', san-serif;
      font-weight: 500;
    }

    .ant-select-selector {
      height: 45px;
      border-radius: 10px;
      padding-top: 14px;

      color: #1b1464;
      border-color: #dfe8f1;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 600;
    }

    .ant-picker-input > input {
      top: 8px;
      color: #1b1464;
      font-family: 'Roboto', san-serif;
      font-weight: 600;
      font-size: 14px;
    }

    .Select-Centro {
      .ant-select-selector {
        background: #dfe8f1;
      }
    }
  }

  #page-wrap {
    height: 100%;
    overflow: auto;
    text-align: center;
    overflow: auto;
    //top: 45%;
    //position: relative;
  }

  .bm-menu-wrap {
    right: 0px !important;
  }

  .bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
  }

  .bm-item:hover {
    color: white;
  }

  

  .bm-burger-button {
    position: absolute;
    width: 30px;
    height: 22px;
    right: 36px;
    top: 27px;
  }

  /* Color/shape of burger icon bars */

  ${(props) => props.hamburguercolor && css`
  .bm-burger-bars {
    background: ${props.hamburguercolor};
    border-radius: 10px;
    height: 10% !important;
  }
  ` }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /* General sidebar styles */
  .bm-menu {
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    width: inherit;
    background: #1b1464;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgb(27, 20, 100) !important;
    opacity: 0.3 !important;
  }
`;

const SearcherContainer = () => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const isDesktop = useMediaQuery('(min-width: 609px)');
  const [menuSelectedKey, setMenuSelectedKey] = useState('/searcher');
  const [hamburguerColor, setHamburguerColor] = useState("#ffff");

  useEffect(() => {
    if (location.pathname) {
      setMenuSelectedKey(location.pathname);
    }
  }, [location]);

  const changeHamburguerColor = (color) => setHamburguerColor(color);

  return (
    <Suspense fallback={<LoaderComponent />}>
      <Router>
        <Layout hamburguercolor={hamburguerColor}>
          {isDesktop ? (
            <>
              <AntHeader style={{ background: '#ffff', minHeight: '80px', padding: '0px 31px 0px 60px' }}>
                <Row style={{ maxWidth: '1440px', margin: 'auto' }}>
                  <Col xs={6} sm={5} md={5} lg={6} xl={6} xxl={6} className='Col-left-logo'>
                    <Link to=''>
                      <LogoHorizontal />
                    </Link>
                  </Col>
                  <Col xs={10} sm={11} md={13} lg={13} xl={14} xxl={14}>
                    <Menu
                      mode='horizontal'
                      defaultSelectedKeys={menuSelectedKey}
                      selectedKeys={menuSelectedKey}
                      style={{ borderBottom: '0px', justifyContent: 'end', paddingTop: '7px', fontWeight: '500', fontFamily: '"Roboto", sans-serif', color: '#1E3799', fontSize: '16px' }}>
                      <Menu.Item key='/searcher' onClick={() => setMenuSelectedKey('/searcher')}>
                        <Link to='/searcher'>Todas las Canchas</Link>
                      </Menu.Item>
                      <Menu.Item key='/searcher/sportscenter' onClick={() => setMenuSelectedKey('/searcher/sportscenter')}>
                        <Link to='/searcher/sportscenter'>Centros Deportivos</Link>
                      </Menu.Item>
                    </Menu>
                  </Col>
                  <Col xs={8} sm={8} md={6} lg={5} xl={4} xxl={4}>
                    <ModalS isDesktop={isDesktop} />
                  </Col>
                </Row>
              </AntHeader>
            </>
          ) : (
            <>
              <SideBar2 pageWrapId={'page-wrap'} right />
            </>
          )}
          <Content >
            <Switch>
              {InnerRoutes.map((route, index) => (
                <Route key={index} path={`${url}/${route.path}`} exact={route.exact}>
                  <route.component hamburguercolor={changeHamburguerColor} />
                </Route>
              ))}
              <Redirect to='/searcher' />
            </Switch>
          </Content>
        </Layout>
      </Router>
    </Suspense >
  );
};

export default SearcherContainer;
// ant-menu-item-selected
