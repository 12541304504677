import styled from 'styled-components';
import { Modal as AntModal } from 'antd';

export const Modal = styled(AntModal)`
  font-family: Roboto;

  .Row-logoContent {
    position: absolute;
    top: -36px;
    left: 41%;
  }

  .ant-modal-content {
    height: auto;
    border-radius: 20px;
    text-align: center;
  }
  .ant-modal-body {
    padding-top: 60px;
  }

  .Row-ContentTitle {
    display: block;
  }

  .Row-inputPhone {
    padding: 15px 0 30px;
    .react-tel-input .flag-dropdown {
      border-radius: 10px 0 0 10px;
    }
    .react-tel-input .selected-flag {
      border-radius: 10px 0 0 10px;
      width: 48px;
      padding: 0 0 0 13px;
    }

    .react-tel-input .form-control {
      padding-left: 60px;
      padding-top: 14px;
    }

    label {
      position: absolute;
      z-index: 10;
      font-size: 12px;
      top: 6px;
      left: 70px;
      font-weight: 500;
      color: #9daab8;
    }
  }

  .Row-Content--Formregister {
    label {
      position: absolute;
      z-index: 10;
      font-size: 12px;
      top: 6px;
      left: 20px;
      font-weight: 500;
      color: #9daab8;
    }
    .ant-input {
      height: 58px;
      border-radius: 10px;
      border-color: #9daab8;
    }

    .ant-select {
      width: 100%;
    }
    .ant-select-selector {
      height: 58px;
      border-radius: 10px;
      text-align: start;
      padding-top: 14px;
      border: 1px solid #9daab8;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: #9daab8;
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #9daab8;
    }
  }

  .Row-ContetOtpInput {
    .ant-col {
      display: flex;
      justify-content: center;
      padding: 15px 0 30px;
    }
  }

  .Row-Content--button {
    padding-top: 30px;
  }

  .Col-text {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 55px;

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.03em;
      padding-right: 10px;
      margin: 0;
    }
  }

  h1 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: #1b1464;
  }

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    color: #1b1464;
  }

  .ant-btn-dangerous.ant-btn-primary {
    border-radius: 50px;
    width: 55px;
    height: 55px;
  }

  .ant-btn {
    padding: 15px;
    svg {
      height: 23px;
      width: 23px;
    }
  }

  .messageSuccess {
    color: #000000;
    font-weight: bold;
  }

  .messageFailed {
    color: #f5222d;
    font-weight: bold;
  }

  .Row-button {
    margin: 20px 0;

    .ant-btn {
      width: auto;
      background: #00e676;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #ffff;
      height: 44px;
      border: none;
      border-radius: 10px;
      padding: 0 15px;
    }

    .btn-cancel {
      width: 148px;
      background: #f5222d;
    }
  }
`;

export default Modal;
